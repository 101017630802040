import React from 'react';
import { AnimatePresence } from 'framer-motion';

export const onRouteUpdate = (routeData) => {
  if (routeData.location.hash) {
    setTimeout(() => {
      try {
        const currentDocument = document?.querySelector(routeData.location.hash);
        if (currentDocument !== undefined && currentDocument !== null) {
          const y = currentDocument.getBoundingClientRect().top - 84;

          window.scrollTo({ top: y, behavior: 'smooth' });
        }
        else window.scrollTo({ top: 0, behavior: 'smooth' });
      } catch (err) {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }
    }, 500);

    setTimeout(() => {
      history.pushState('', document.title, window.location.pathname + window.location.search);
    }, 50);
  }
};

export const wrapPageElement = ({ element }) => (
  <AnimatePresence exitBeforeEnter>{element}</AnimatePresence>
);
